import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import {
  memo,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import ActivityContext from "components/Analytics/ActivityContext";
import {
  facebookButtonStyles,
  socialImportStyles,
  twitterButtonStyles,
} from "components/Auth/AuthForms/Layout/loginStyles";
import SocialConnectActionButton from "components/Auth/SocialConnectActionButton";
import SocialConnectErrorMessage from "components/Auth/SocialConnectErrorMessage";
import useAuthModalContext from "components/Auth/useAuthModalContext";
import Steps from "components/Common/Steps";
import Tabs from "components/Common/Tabs";

import FollowStageLoading from "./FollowStageLoading";

import authActions from "actions/auth";
import { connectSocialEntities } from "api/live/auth";
import {
  selectSocialConnectSyncDetails,
  selectSocialConnectionError,
} from "selectors/auth";
import { capitalize } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import usePreAuthData from "hooks/usePreAuthData";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faXTwitter from "styles/icons/faXTwitter";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  followStageList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  connectArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "1.5rem",
  },
  connectDescription: {
    ...gStyles.fontSemiBold,
    fontSize: "1rem",
    lineHeight: "2rem",
    color: colours.grey,
    textAlign: "center",
    marginBottom: "2.5rem",
    maxWidth: "28rem",
  },
  socialButtonContainer: {
    width: "11rem",
  },

  noResultsMessage: {
    textAlign: "center",
    width: "100%",
    padding: "2rem 0",
  },
  noConnectResults: {
    ...gStyles.fontSemiBold,
    fontSize: "1rem",
    lineHeight: "2rem",
    color: colours.grey,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "0 1rem 2rem",
    margin: "2.5rem auto 0",
    maxWidth: "28rem",
  },
  ...socialImportStyles,
};

const stepStyles = {
  stepsContainer: {
    overflowX: "visible",
    minHeight: "initial",
  },
};

const TABS_STYLES = {
  tabs: {
    marginBottom: "1.5rem",
  },
  tabContainer: {
    [ScreenSizes.lgAndAbove]: {
      padding: "0 1rem",
    },
  },
  tab: {
    fontSize: "1rem",
    ":hover": {
      opacity: 0.85,
    },
    ":focus": {
      opacity: 0.75,
      borderBottomColor: colours.greyishBlueHighlight,
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: ".875rem",
    },
  },
  currentTab: {
    ":active": {
      opacity: 1,
    },
    ":hover": {
      opacity: 1,
    },
  },
  "currentTab-suggested": {
    color: colours.primary,
    borderBottomColor: colours.primary,
    ":active": {
      opacity: 1,
    },
    ":focus": {
      opacity: 1,
      borderBottomColor: colours.primary,
    },
  },
  "currentTab-twitter": {
    color: colours.black,
    borderBottomColor: colours.black,
    [ScreenSizes.lgAndAbove]: {
      ":active": {
        opacity: 1,
      },
      ":focus": {
        opacity: 1,
        borderBottomColor: colours.black,
      },
    },
  },
  "currentTab-facebook": {
    color: facebookButtonStyles.active.backgroundColor,
    borderBottomColor: facebookButtonStyles.active.backgroundColor,
    [ScreenSizes.lgAndAbove]: {
      ":active": {
        opacity: 1,
      },
      ":focus": {
        opacity: 1,
        borderBottomColor: facebookButtonStyles.active.backgroundColor,
      },
    },
  },
  tabBadgeBefore: {
    color: "inherit",
    backgroundColor: "initial",
    marginRight: ".5rem",
    paddingLeft: 0,
    fontSize: "1rem",
  },
};

const TABS = [
  {
    key: "suggested",
    title: "Suggested",
    renderTooltip: () => null,
  },
  {
    key: "twitter",
    title: "X/Twitter",
    socialConnect: true,
    badgeBeforeTitle: true,
    badge: <FontAwesomeIcon icon={faXTwitter} />,
    icon: faXTwitter,
    renderTooltip: () => "Connect and import from Twitter",
    styles: twitterButtonStyles,
  },
  {
    key: "facebook",
    title: "Facebook",
    socialConnect: true,
    badgeBeforeTitle: true,
    badge: <FontAwesomeIcon icon={faFacebookF} />,
    icon: faFacebookF,
    renderTooltip: () => "Connect and import from Facebook",
    styles: facebookButtonStyles,
  },
];

const DEFAULT_TAB = TABS[0].key;

const FollowStageBase = (props) => {
  const {
    listKey: passedListKey,
    entity_type,
    stacked,
    setSocialConnect,
    socialConnect,
    preAuthAdditionalParams,
    getConnectReturnUrl,
    socialButtons: passedSocialButtons,
    tabsStyles: passedTabsStyles,
    renderSuggestionsComponent,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const [currentTabKey, setCurrentTabKey] = useState(
    socialConnect || DEFAULT_TAB
  );

  const activityContext = useContext(ActivityContext) || {};
  const authModalContext = useAuthModalContext();

  useEffect(() => {
    if (socialConnect) {
      setCurrentTabKey(socialConnect);
    }
  }, [socialConnect]);

  const analyticsParams = useMemo(
    () => ({
      ...activityContext,
      ...(authModalContext
        ? {
            modal:
              activityContext && activityContext.in_onboarding
                ? "onboarding"
                : "registration",
            modalStage: authModalContext.registrationStage,
          }
        : {}),
      socialTab: socialConnect || "default",
    }),
    [activityContext, authModalContext, socialConnect]
  );

  const user = useLoggedInUser();
  const isConnected =
    user && user.get("connected_social_networks", []).includes(currentTabKey);

  const { currentTabIndex, currentTab } = useMemo(() => {
    const tabIndex = TABS.findIndex((tab) => tab.key === currentTabKey);

    return {
      currentTab: TABS[tabIndex],
      currentTabIndex: tabIndex,
    };
  }, [currentTabKey]);

  const { setSocialConnectSyncDetails, setSocialConnectionError } =
    useActionCreators({
      setSocialConnectSyncDetails: authActions.setSocialConnectSyncDetails,
      setSocialConnectionError: authActions.setSocialConnectionError,
    });

  const socialConnectTypes = useMemo(
    () => passedSocialButtons || ["twitter" /* , "facebook" */],
    [passedSocialButtons]
  );

  const socialConnectSyncDetails = useReduxState(
    (state) =>
      selectSocialConnectSyncDetails(state, socialConnect, entity_type),
    [socialConnect, entity_type]
  );

  const allSyncDetailsLoaded = useMemo(
    () =>
      socialConnectTypes.every(
        (type) =>
          !user ||
          !user.get("connected_social_networks", []).includes(type) ||
          !!socialConnectSyncDetails
      ),
    [user, socialConnectSyncDetails, socialConnectTypes]
  );

  const socialConnectionError = useReduxState(
    (state) => selectSocialConnectionError(state, socialConnect, entity_type),
    [socialConnect, entity_type]
  );

  const connecting =
    currentTab.socialConnect && isConnected && !socialConnectSyncDetails;
  const socialHasMatches =
    isConnected &&
    socialConnectSyncDetails &&
    socialConnectSyncDetails.total_matches > 0;

  const listSocialConnect = socialHasMatches ? currentTabKey : DEFAULT_TAB;
  const listKey = `${passedListKey}/${entity_type}/${listSocialConnect}`;
  const noSocialResults =
    socialConnect &&
    socialConnectSyncDetails &&
    socialConnectSyncDetails.total_matches === 0;

  const { requestTokensLoaded, failedRequestTokens } = usePreAuthData(
    preAuthAdditionalParams
  );

  const tabsStyles = useMemo(() => {
    if (passedTabsStyles) {
      return deepmerge(TABS_STYLES, passedTabsStyles);
    }

    return TABS_STYLES;
  }, [passedTabsStyles]);

  const tabs = useMemo(
    () =>
      TABS.filter(
        (buttonProps) =>
          !buttonProps.socialConnect ||
          socialConnectTypes.includes(buttonProps.key)
      ).map((button) => {
        const disabled = failedRequestTokens.includes(button.key);

        return {
          ...button,
          actionContext: `followStage-${listKey}`,
          disabled,
          disabledTitle: disabled
            ? `Unable to connect to ${capitalize(button.key)}`
            : null,
        };
      }),
    [socialConnectTypes, failedRequestTokens, listKey]
  );

  const handleUnselectSocialClick = useCallback(() => {
    setSocialConnect(null);
  }, [setSocialConnect]);

  useEffect(() => {
    if (!allSyncDetailsLoaded) {
      socialConnectTypes.forEach((socialType) => {
        if (
          !user ||
          !user.get("connected_social_networks", []).includes(socialType)
        ) {
          return;
        }
        connectSocialEntities(entity_type, socialType)
          .then((response) => {
            setSocialConnectSyncDetails(socialType, entity_type, response);
          })
          .catch(() => {
            setSocialConnectionError(socialType, entity_type, true);
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity_type, user]);

  const handleConnectedClick = useCallback(
    (config) => () => setSocialConnect(config.type),
    [setSocialConnect]
  );

  const handleTabChange = useCallback(
    (tab) => {
      setCurrentTabKey(tab.key);
      setSocialConnect(tab.socialConnect ? tab.key : null);
      sendGAEvent({
        action: "followStageTabSelect",
        tabKey: tab.key,
        context: `followStage-${listKey}`,
        ...analyticsParams,
      });
    },
    [analyticsParams, listKey, setSocialConnect]
  );

  const renderSocialImportButton = useCallback(
    (config) => {
      const isSelected = socialConnect === config.key;
      const submitting = !requestTokensLoaded;

      return (
        <SocialConnectActionButton
          key={config.key}
          type={config.key}
          isSelected={isSelected}
          label={`Connect ${capitalize(config.key)}`}
          returnUrl={getConnectReturnUrl(config.key)}
          onConnectedClick={handleConnectedClick(config)}
          onUnselect={handleUnselectSocialClick}
          buttonStyles={config.styles}
          stacked={stacked}
          disabled={config.disabled}
          title={config.disabledTitle}
          submitting={submitting}
          error={
            socialConnectionError === config.key &&
            `Sorry we were unable to get ${entity_type}s from your ${config.type} profile`
          }
          renderTooltip={config.renderTooltip}
          actionContext={config.actionContext}
          noActiveState
          disableErrors
        />
      );
    },
    [
      entity_type,
      getConnectReturnUrl,
      handleConnectedClick,
      handleUnselectSocialClick,
      requestTokensLoaded,
      socialConnect,
      socialConnectionError,
      stacked,
    ]
  );

  const renderConnectArea = useCallback(
    (tab) => (
      <div className={css(styles.connectArea)}>
        <div className={css(styles.connectDescription)}>
          <SocialConnectErrorMessage
            type={socialConnect}
            errorAsText
            replaceChildren
            redError
            clearErrorOnUnmount
          >
            {`Connect your ${capitalize(
              socialConnect
            )} account to see suggestions based on who you follow on ${capitalize(
              socialConnect
            )}`}
          </SocialConnectErrorMessage>
        </div>
        <div className={css(styles.socialButtonContainer)}>
          {renderSocialImportButton(tab)}
        </div>
      </div>
    ),
    [
      socialConnect,
      renderSocialImportButton,
      styles.connectArea,
      styles.connectDescription,
      styles.socialButtonContainer,
    ]
  );

  const renderNoConnectResults = useCallback(
    () => (
      <div className={css(styles.noConnectResults)}>
        {`We couldn't find any ${entity_type}s followed by your ${capitalize(
          socialConnect
        )} account.`}
      </div>
    ),
    [entity_type, socialConnect, styles.noConnectResults]
  );

  const renderTabContent = useCallback(
    (tab) => {
      if (currentTabKey !== DEFAULT_TAB && currentTabKey !== socialConnect) {
        return <FollowStageLoading isLoading />;
      }
      if (!tab.socialConnect || isConnected) {
        if (connecting) {
          return <FollowStageLoading isLoading />;
        }
        if (noSocialResults) {
          return renderNoConnectResults();
        }

        return renderSuggestionsComponent({
          isConnected,
          socialConnectFromTab: tab.socialConnect,
          listKey,
          defaultTab: DEFAULT_TAB,
          currentTabKey,
          connecting,
        });
      }

      return renderConnectArea(tab);
    },
    [
      currentTabKey,
      socialConnect,
      isConnected,
      renderConnectArea,
      connecting,
      noSocialResults,
      renderSuggestionsComponent,
      listKey,
      renderNoConnectResults,
    ]
  );

  const renderTabsLayout = useCallback(
    (tabProps) => (
      <Fragment>
        {tabProps.tabs}
        <Steps
          steps={tabs}
          renderStep={renderTabContent}
          currentStepIndex={currentTabIndex}
          styles={stepStyles}
        />
      </Fragment>
    ),
    [renderTabContent, currentTabIndex, tabs]
  );

  const renderTabs = () => (
    <Tabs
      tabs={tabs}
      tabKey={currentTabKey}
      renderLayout={renderTabsLayout}
      onTabChange={handleTabChange}
      styles={tabsStyles}
    />
  );

  return (
    <ActivityContext.Provider value={analyticsParams}>
      <div className={css(styles.followStageList)}>{renderTabs()}</div>
    </ActivityContext.Provider>
  );
};

FollowStageBase.propTypes = {
  listKey: PropTypes.string.isRequired,
  setSocialConnect: PropTypes.func.isRequired,
  getConnectReturnUrl: PropTypes.func.isRequired,
  socialConnect: PropTypes.string,
  entity_type: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
  preAuthAdditionalParams: PropTypes.object,
  socialButtons: PropTypes.array,
  tabsStyles: PropTypes.object,
  renderSuggestionsComponent: PropTypes.func.isRequired,
};

FollowStageBase.defaultProps = {
  socialConnect: null,
  infiniteListStyles: null,
  preAuthAdditionalParams: {},
  socialButtons: null,
  tabsStyles: null,
};

export default memo(FollowStageBase);
