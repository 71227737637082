import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";

import authActions from "actions/auth";
import { selectSocialConnectDenial } from "selectors/auth";
import { capitalizeWords } from "utils/case";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  errorPopover: {
    ...gStyles.fontMedium,
    fontSize: ".875rem",
    maxWidth: "20rem",
    width: "max-content",
  },
  redError: {
    color: colours.negative,
  },
};

const SocialConnectErrorMessage = (props) => {
  const {
    children,
    type,
    className,
    disableErrors,
    errorAsText,
    redError,
    replaceChildren,
    clearErrorOnUnmount,
    aboveChildren,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess } = useWindowSize();

  const { clearSocialConnectError } = useActionCreators({
    clearSocialConnectError: authActions.clearSocialConnectError,
  });

  const socialConnectDenial = useReduxState(
    (state) => selectSocialConnectDenial(state),
    []
  );

  const provider = socialConnectDenial && socialConnectDenial.get("provider");

  const show = type
    ? socialConnectDenial && provider === type
    : socialConnectDenial;

  const handleClearError = useCallback(() => {
    if (socialConnectDenial) {
      clearSocialConnectError();
    }
  }, [socialConnectDenial, clearSocialConnectError]);

  useEffect(() => {
    if (!disableErrors && show && !clearErrorOnUnmount) {
      setTimeout(() => handleClearError(), 12000);
    }

    return () => {
      if (!disableErrors && show && clearErrorOnUnmount) {
        handleClearError();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only on mount

  const renderErrorMessage = () => {
    if (
      socialConnectDenial.get("error") ===
      "EXISTING_ACCOUNT_ALREADY_ATTACHED_TO_SOCIAL_ACCOUNT"
    ) {
      const message = `Another Podchaser account is already linked to the ${capitalizeWords(
        provider
      )} account you've selected`;

      if (errorAsText) {
        return (
          <span data-id="social-connect-error-existing-account">{message}</span>
        );
      }

      return (
        <div
          className={css(styles.errorPopover)}
          data-id="social-connect-error-existing-account"
          onClick={handleClearError}
        >
          {message}
        </div>
      );
    }

    const message = `We were unable to connect to your ${capitalizeWords(
      provider
    )} account, ${
      type
        ? "click this button to try again"
        : `click the ${capitalizeWords(provider)} button to try again`
    }`;

    if (errorAsText) {
      return <span data-id="social-connect-error">{message}</span>;
    }

    return (
      <div
        className={css(styles.errorPopover)}
        data-id="social-connect-error"
        onClick={handleClearError}
      >
        {message}
      </div>
    );
  };

  const renderButton = useCallback(
    (buttonProps) => (
      <div {...buttonProps} className={className} onClick={handleClearError}>
        {children}
      </div>
    ),
    [children, handleClearError, className]
  );

  if (disableErrors) {
    return <div className={className}>{children}</div>;
  }
  if (errorAsText) {
    if (aboveChildren) {
      return (
        <div>
          {show && (
            <div className={css(show && redError && styles.redError)}>
              {renderErrorMessage()}
            </div>
          )}
          {children}
        </div>
      );
    }
    if (replaceChildren) {
      return (
        <div className={css(show && redError && styles.redError)}>
          {show ? renderErrorMessage() : children}
        </div>
      );
    }
    if (redError) {
      if (show) {
        return (
          <div className={css(styles.redError)}>{renderErrorMessage()}</div>
        );
      }

      return null;
    }

    return renderErrorMessage();
  }

  return (
    <ButtonWithPopout
      renderButton={renderButton}
      renderContent={renderErrorMessage}
      positionFixed={isWindowSizeOrLess("medium")}
      isOpen={show}
      expand
      offsetArrow
      disablePortal
    />
  );
};

SocialConnectErrorMessage.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  disableErrors: PropTypes.bool,
  errorAsText: PropTypes.bool,
  redError: PropTypes.bool,
  replaceChildren: PropTypes.bool,
  aboveChildren: PropTypes.bool,
  clearErrorOnUnmount: PropTypes.bool,
};

SocialConnectErrorMessage.defaultProps = {
  children: null,
  className: null,
  type: null,
  disableErrors: false,
  errorAsText: false,
  redError: false,
  replaceChildren: false,
  aboveChildren: false,
  clearErrorOnUnmount: false,
};

export default SocialConnectErrorMessage;
