import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState, useContext } from "react";
import { followEntityPromise, unfollowEntityPromise } from "routines/follow";

import ActivityContext from "components/Analytics/ActivityContext";
import useAuthModalContext from "components/Auth/useAuthModalContext";
import StandardButton from "components/Buttons/StandardButton";
import SearchAutocomplete from "components/Search/SearchAutocomplete";

import sendGAEvent from "utils/sendGAEvent";

import { useEntities } from "hooks/useEntity";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  searchRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "1.5rem",
  },
  search: {
    width: "100%",
    maxWidth: "20rem",
    marginRight: ".75rem",
  },
  followAllButtonContainer: {
    minWidth: "7rem",
    width: "7rem",
  },
};

const searchStyles = {
  search: {
    border: "1px solid #e2e2e2",
    boxShadow: "none",

    ":hover": {
      boxShadow: "none",
    },
  },
  icon: {
    color: "var(--color-neutral-d6)",

    [ScreenSizes.lgAndAbove]: {
      marginLeft: "0.625rem",
      color: "var(--color-neutral-d5)",
    },
  },
  input: {
    [ScreenSizes.mdAndAbove]: {
      paddingLeft: "1em",
      lineHeight: 1,
      paddingTop: "0.6125em",
      paddingBottom: "0.6125em",
      height: 44,
    },
    "::placeholder": {
      ...gStyles.fontRegularItalic,
      color: "#3e434d",
      fontSize: 13,
      letterSpacing: "0.03px",
    },
  },
};

const followAllButtonStyles = {
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: ".75rem",
    height: "2.75rem",
    padding: "0 1.5rem",
    color: colours.oldSecondary,
    backgroundColor: colours.white,
    border: "1px solid #eef0f2",
    ":hover": {
      backgroundColor: "#f5f5f5",
    },
    ":focus": {
      backgroundColor: "#f5f5f5",
    },
  },
};

const FollowStageBaseSuggestionsSearchRow = ({
  socialConnect,
  entity_type,
  loading,
  search_term,
  listKey,
  items,
  handleSearchChange,
  handleSearchClose,
}) => {
  const { styles } = useStyles(baseStyles);

  const [following, setFollowing] = useState(false);

  const { followEntity, unfollowEntity } = useRoutinePromises({
    followEntity: followEntityPromise,
    unfollowEntity: unfollowEntityPromise,
  });

  const entities = useEntities(entity_type, items);

  const allFollowed = useMemo(
    () =>
      entities &&
      entities.size > 0 &&
      entities.every(
        (ent) => ent.entity && ent.entity.getIn(["user_data", "follows"])
      ),
    [entities]
  );

  const activityContext = useContext(ActivityContext) || {};
  const authModalContext = useAuthModalContext();

  const analyticsParams = useMemo(
    () => ({
      ...activityContext,
      ...(authModalContext
        ? {
            modal:
              activityContext && activityContext.in_onboarding
                ? "onboarding"
                : "registration",
            modalStage: authModalContext.registrationStage,
          }
        : {}),
      socialTab: socialConnect || "default",
    }),
    [activityContext, authModalContext, socialConnect]
  );

  const handleFollowAll = useCallback(() => {
    if (!following && items.size > 0) {
      setFollowing(true);
      const followFunc = allFollowed ? unfollowEntity : followEntity;
      const ids = allFollowed
        ? items
        : entities
            .filter((ent) => !ent.entity.getIn(["user_data", "follows"]))
            .map((ent) => ent.entity.get("id"));

      sendGAEvent({
        action: `${allFollowed ? "unfollow" : "follow"}AllClicked`,
        context: `followStage-${listKey}`,
        ...analyticsParams,
      });

      followFunc({
        entity_type,
        many: true,
        subscribe: true,
        ids: ids.toJS ? ids.toJS() : ids,
      }).finally(() => setFollowing(false));
    }
  }, [
    following,
    items,
    allFollowed,
    unfollowEntity,
    followEntity,
    entities,
    listKey,
    analyticsParams,
    entity_type,
  ]);

  return (
    <div className={css(styles.searchRow)}>
      <div className={css(styles.search)}>
        <SearchAutocomplete
          placeholder={
            socialConnect ? "Filter results" : `Find any ${entity_type}...`
          }
          searching={loading}
          onInputValueChange={handleSearchChange}
          search_term={search_term}
          cancelSearch={handleSearchClose}
          searchFieldStyles={searchStyles}
          hideResults
          white
          ariaLabel="Search"
        />
      </div>
      <div className={css(styles.followAllButtonContainer)}>
        <StandardButton
          dataId="follow-stage-base-follow-all"
          dataValue={allFollowed ? "unfollow" : "follow"}
          customStyles={followAllButtonStyles}
          label={allFollowed ? "Unfollow All" : "Follow All"}
          onClick={handleFollowAll}
          submitting={following}
          accessible
        />
      </div>
    </div>
  );
};

FollowStageBaseSuggestionsSearchRow.propTypes = {
  socialConnect: PropTypes.string,
  entity_type: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  search_term: PropTypes.string.isRequired,
  listKey: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(List),
  handleSearchChange: PropTypes.func.isRequired,
  handleSearchClose: PropTypes.func.isRequired,
};

FollowStageBaseSuggestionsSearchRow.defaultProps = {
  loading: false,
};

export default FollowStageBaseSuggestionsSearchRow;
