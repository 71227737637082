import { css } from "aphrodite";
import PropTypes from "prop-types";

import LoadingOverlay from "components/Common/LoadingOverlay";

import { useStyles } from "hooks/useStyles";

const loadingOverlayStyles = {
  noOverlay: {
    marginTop: "2rem",
  },
};

const baseStyles = {
  loadingContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: "1rem",
    width: "100%",
  },
  loadingStage: {
    marginTop: "1rem",
  },
};

const FollowStageLoading = (props) => {
  const { isLoading } = props;
  const { styles } = useStyles(baseStyles);

  return (
    <div
      className={css(styles.loadingContainer, isLoading && styles.loadingStage)}
    >
      <LoadingOverlay
        styles={loadingOverlayStyles}
        key="followStageBaseLoadingOverlay"
        noOverlay
        noPadding
      />
    </div>
  );
};

FollowStageLoading.propTypes = {
  isLoading: PropTypes.bool,
};

FollowStageLoading.defaultProps = {
  isLoading: false,
};

export default FollowStageLoading;
