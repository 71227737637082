import { userSchema } from "api/schema/user";
import { normalize } from "normalizr";
import pickBy from "ramda/src/pickBy";
import { CANCEL } from "redux-saga";

import client from "./client";

import { setToken } from "utils/auth";
import { keyIn } from "utils/misc";

function filterAdditionalAuthKeys(values) {
  return pickBy(
    keyIn(
      "top_podcasts",
      "accepted_terms",
      "password_confirmation",
      "has_podcast",
      "podcast_name",
      "recaptchaToken",
      "incomplete_user_id",
      ...Object.keys(values).filter((k) => k.match(/^found_podchaser_on/))
    ),
    values
  );
}

export function authenticate({ username, password, ...passedRest }) {
  const rest = filterAdditionalAuthKeys(passedRest);

  return client
    .post(
      "login/",
      {
        username,
        password,
        ...rest,
      },
      { withCredentials: true }
    )
    .then((response) => {
      setToken(response.data.token);
      const normalized = normalize(
        { user: response.data.user },
        { user: userSchema }
      );
      return { ...response.data, ...normalized };
    });
}

export function register({
  username,
  display_name,
  password,
  email,
  claim_token,
  profile_image_url,
  podcast_id,
  ...passedMore
}) {
  const more = filterAdditionalAuthKeys(passedMore);

  return client
    .post(
      "register/",
      {
        username,
        password,
        email,
        claim_token,
        profile_image_url,
        display_name,
        podcast_id,
        ...more,
      },
      { withCredentials: true }
    )
    .then((response) => {
      const normalized = normalize(
        { user: response.data.user },
        { user: userSchema }
      );
      return { ...response.data, ...normalized };
    });
}

export function socialServerAuth({
  provider,
  code,
  user_id,
  password,
  username,
  claim_token,
  ...more
}) {
  return client
    .post(
      `social/${provider}/auth`,
      {
        code,
        user_id,
        password,
        username,
        claim_token,
        ...more,
      },
      { withCredentials: true }
    )
    .then((response) => {
      setToken(response.data.token);
      const normalized = normalize(
        { user: response.data.user },
        { user: userSchema }
      );
      return {
        ...response,
        data: {
          ...response.data,
          user: normalized.entities.users[normalized.result.user],
        },
        ...normalized,
      };
    });
}

export function getAccessToken({ code, provider, host }) {
  return client
    .post(
      `social/${provider}/token`,
      {
        code,
        provider,
        host,
      },
      { withCredentials: true }
    )
    .then((response) => response.data);
}

export function getRequestToken({ provider, host, state }) {
  return client
    .post(
      `social/${provider}/request_token`,
      {
        provider,
        host,
        state,
      },
      { withCredentials: true }
    )
    .then((response) => response.data);
}

export function loadUserData({ cancelToken }) {
  const request = client
    .get("/my/user", { cancelToken: cancelToken.token })
    .then((response) => {
      const normalized = normalize(
        { user: response.data },
        { user: userSchema }
      );
      return normalized;
    });

  request[CANCEL] = () => cancelToken.cancel();
  return request;
}

export function verifyBetaKey(key, claimToken = null) {
  return client
    .post("/beta/verify", {
      key,
      claim_token: claimToken,
    })
    .then((response) => response.data);
}

export function requestPasswordReset({ email, username }) {
  return client
    .post("/auth/request_password_reset", {
      email,
      username,
    })
    .then((response) => response.data);
}

export function passwordReset(props) {
  return client
    .post("/auth/password_reset", props)
    .then((response) => response.data);
}

export function connectSocialEntities(entity_type, provider) {
  return client
    .post(`/social_entities/${entity_type}/${provider}`)
    .then((response) => response.data);
}
