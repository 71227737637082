import { css } from "aphrodite";
import PropTypes from "prop-types";

import { selectSocialConnectSyncDetails } from "selectors/auth";
import { capitalize } from "utils/misc";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  followStageSuggetionsTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
  },
  followStageSubTitle: {
    ...gStyles.avalonBold,
    fontSize: ".875rem",
    textAlign: "center",
  },
  followStageSuggetionsTitle: {
    marginRight: "1.25rem",
    ":last-child": {
      marginRight: 0,
    },
  },
};

const FollowStageBaseSuggestionsSubTitle = ({
  search_term,
  socialConnect,
  suggestionsTitle: passedSuggestionsTitle,
  entity_type,
  isConnected,
  socialConnectFromTab,
}) => {
  const { styles } = useStyles(baseStyles);

  const socialConnectSyncDetails = useReduxState(
    (state) =>
      selectSocialConnectSyncDetails(state, socialConnect, entity_type),
    [socialConnect, entity_type]
  );

  const noSocialResults =
    socialConnect &&
    socialConnectSyncDetails &&
    socialConnectSyncDetails.total_matches === 0;

  const noSearchResults = search_term && !noSocialResults;

  if (noSearchResults || !search_term || passedSuggestionsTitle) {
    let suggestionsTitle = !search_term && passedSuggestionsTitle;

    if (noSearchResults) {
      suggestionsTitle = `Results for "${search_term}"`;
    }
    if (socialConnectFromTab && isConnected) {
      suggestionsTitle = `${capitalize(
        entity_type
      )}s based on who you follow on ${capitalize(socialConnect)}`;
    }

    return (
      <div className={css(styles.followStageSuggetionsTitleContainer)}>
        <div
          className={css(
            styles.followStageSubTitle,
            styles.followStageSuggetionsTitle
          )}
        >
          {suggestionsTitle}
        </div>
      </div>
    );
  }

  return null;
};

FollowStageBaseSuggestionsSubTitle.propTypes = {
  search_term: PropTypes.string.isRequired,
  socialConnect: PropTypes.string,
  suggestionsTitle: PropTypes.node,
  entity_type: PropTypes.string,
  isConnected: PropTypes.bool,
  socialConnectFromTab: PropTypes.bool,
};

export default FollowStageBaseSuggestionsSubTitle;
