import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useState, useCallback, useMemo } from "react";

import InfiniteList from "components/Common/Lists/InfiniteList";
import FollowStageBaseSuggestionsSearchRow from "components/Entities/Page/FollowStageBaseSuggestionsSearchRow";
import FollowStageBaseSuggestionsSubTitle from "components/Entities/Page/FollowStageBaseSuggestionsSubTitle";
import FollowStageLoading from "components/Entities/Page/FollowStageLoading";

import paginationActions from "actions/pagination";
import { BADGES } from "constants/badges";
import * as sortConstants from "constants/sort";
import loadUsersList from "sagas/pagination/lists/loadUsersList";
import { selectSocialConnectSyncDetails } from "selectors/auth";
import isEmpty from "utils/isEmpty";

import useActionCreators from "hooks/useActionCreators";
import useList from "hooks/useList";
import useLoadListEffect from "hooks/useLoadListEffect";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  suggestionsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: "1rem",
    width: "100%",
  },
};

const LIST_KEY_FOR_BADGES = "onboarding/follow/users_with_badge/users";

const LIST_CONFIG_FOR_BADGES = {
  sort: sortConstants.SORT_ORDER_POPULAR,
  sort_direction: "desc",
  list_type: "users_filtered",
  loadListAction: loadUsersList,
  entity_type: "user",
  staticFilters: {
    profile_image: {
      value: "has_image",
    },
    badges: {
      value: [BADGES.SUPER_REVIEWER.id],
    },
  },
  pageSize: 14,
};

const FollowEntityStageSuggestions = (props) => {
  const {
    socialConnect,
    suggestionsTitle,
    entity_type,
    isConnected,
    socialConnectFromTab,
    listKey,
    defaultTab,
    currentTabKey,
    suggestionsListConfig,
    connecting,
    infiniteListStyles,
    renderSuggestionItem,
  } = props;
  const { styles } = useStyles(baseStyles);

  const [search_term, setStateSearchTerm] = useState("");

  const socialConnectSyncDetails = useReduxState(
    (state) =>
      selectSocialConnectSyncDetails(state, socialConnect, entity_type),
    [socialConnect, entity_type]
  );

  const socialHasMatches =
    isConnected &&
    socialConnectSyncDetails &&
    socialConnectSyncDetails.total_matches > 0;

  const listSocialConnect = socialHasMatches ? currentTabKey : defaultTab;

  const listFilters = useMemo(() => {
    if (socialHasMatches) {
      return {
        filters: {
          by_social_network: { value: listSocialConnect },
        },
      };
    }

    return {};
  }, [listSocialConnect, socialHasMatches]);

  useLoadListEffect(listKey, {
    ...suggestionsListConfig,
    ...listFilters,
  });

  useLoadListEffect(LIST_KEY_FOR_BADGES, {
    ...LIST_CONFIG_FOR_BADGES,
    ...listFilters,
  });

  const { loading: pulledLoading, ids: popularUsersIds } = useList(listKey);
  const { loading: loadingUsersWithBadges, ids: usersIdsWithBadges } =
    useList(LIST_KEY_FOR_BADGES);
  const loading = pulledLoading || connecting || loadingUsersWithBadges;

  const popularUsersIdsWithoutBadgedUsers = popularUsersIds.reduce(
    (acc, userId) => {
      if (usersIdsWithBadges.includes(userId)) {
        return acc;
      }
      return acc.push(userId);
    },
    List()
  );

  const { nextPage, setSearchTerm, applyConfig } = useActionCreators({
    nextPage: paginationActions.nextPage,
    setSearchTerm: paginationActions.setSearchTerm,
    applyConfig: paginationActions.applyConfig,
  });

  const handleLoadMoreForPopularUsers = useCallback(() => {
    if (!loading) {
      nextPage(listKey);
    }
  }, [loading, nextPage, listKey]);

  const handleLoadMoreForBadgeUsers = useCallback(() => {
    if (!loading) {
      nextPage(LIST_KEY_FOR_BADGES);
    }
  }, [loading, nextPage]);

  const resetListConfig = useCallback(() => {
    applyConfig({
      key: listKey,
      ...suggestionsListConfig,
      ...listFilters,
    });
    applyConfig({
      key: LIST_KEY_FOR_BADGES,
      ...LIST_CONFIG_FOR_BADGES,
      ...listFilters,
    });
  }, [applyConfig, listFilters, listKey, suggestionsListConfig]);

  const handleSearchChange = useCallback(
    (newSearchTerm) => {
      setStateSearchTerm(newSearchTerm);

      if (isEmpty(search_term) && !isEmpty(newSearchTerm)) {
        // transition to term search
        applyConfig({
          key: listKey,
          options: {},
          filters: {},
          sort: sortConstants.SORT_ORDER_RELEVANCE,
          ...listFilters,
        });
        applyConfig({
          key: LIST_KEY_FOR_BADGES,
          options: {},
          filters: {},
          sort: sortConstants.SORT_ORDER_POPULAR,
          ...listFilters,
        });
      } else if (isEmpty(newSearchTerm)) {
        resetListConfig();
      }

      // this will trigger the actual search
      setSearchTerm(listKey, newSearchTerm);
    },
    [
      applyConfig,
      listFilters,
      listKey,
      resetListConfig,
      search_term,
      setSearchTerm,
    ]
  );

  const handleSearchClose = useCallback(() => {
    setStateSearchTerm("");
    setSearchTerm(listKey, "");
    setSearchTerm(LIST_KEY_FOR_BADGES, "");
    resetListConfig();
  }, [setSearchTerm, listKey, resetListConfig]);

  const isConnecting = !socialConnect || !connecting;

  return (
    <Fragment>
      <FollowStageBaseSuggestionsSubTitle
        search_term={search_term}
        socialConnect={socialConnect}
        suggestionsTitle={suggestionsTitle}
        entity_type={entity_type}
        isConnected={isConnected}
        socialConnectFromTab={socialConnectFromTab}
      />
      <FollowStageBaseSuggestionsSearchRow
        socialConnect={socialConnect}
        entity_type={entity_type}
        loading={loading}
        search_term={search_term}
        listKey={listKey}
        items={popularUsersIdsWithoutBadgedUsers}
        handleSearchChange={handleSearchChange}
        handleSearchClose={handleSearchClose}
      />
      <div>
        <div
          className={css(styles.suggestionsContainer)}
          data-id="follow-stage-base-suggestions"
        >
          {isConnecting &&
            usersIdsWithBadges?.size > 0 &&
            entity_type === "user" && (
              <InfiniteList
                items={usersIdsWithBadges}
                renderItem={renderSuggestionItem}
                onLoadMore={handleLoadMoreForBadgeUsers}
                styles={infiniteListStyles}
              />
            )}
          {isConnecting && popularUsersIdsWithoutBadgedUsers?.size > 0 && (
            <InfiniteList
              items={popularUsersIdsWithoutBadgedUsers}
              renderItem={renderSuggestionItem}
              onLoadMore={handleLoadMoreForPopularUsers}
              styles={infiniteListStyles}
            />
          )}
          {!loading &&
            search_term &&
            !popularUsersIdsWithoutBadgedUsers?.size === 0 &&
            !usersIdsWithBadges?.size === 0 && (
              <div className={css(styles.noResultsMessage)}>
                No results found.
              </div>
            )}
        </div>
        {loading && <FollowStageLoading />}
      </div>
    </Fragment>
  );
};

FollowEntityStageSuggestions.propTypes = {
  socialConnect: PropTypes.string,
  suggestionsTitle: PropTypes.node,
  entity_type: PropTypes.string.isRequired,
  isConnected: PropTypes.bool,
  socialConnectFromTab: PropTypes.bool,
  listKey: PropTypes.string.isRequired,
  suggestionsListConfig: PropTypes.object.isRequired,
  defaultTab: PropTypes.string.isRequired,
  currentTabKey: PropTypes.string.isRequired,
  renderSuggestionItem: PropTypes.func.isRequired,
  connecting: PropTypes.bool,
  infiniteListStyles: PropTypes.object.isRequired,
};

FollowEntityStageSuggestions.defaultProps = {
  socialConnect: null,
  infiniteListStyles: null,
  suggestionsTitle: null,
  isConnected: false,
};

export default FollowEntityStageSuggestions;
