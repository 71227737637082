import { useEffect, useMemo } from "react";
import { getPreAuthDataPromise } from "routines/socialAuth";

import { selectPreAuthData } from "selectors/auth";

import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";

const usePreAuthData = (additionalParams = {}) => {
  const { getPreAuthData } = useRoutinePromises({
    getPreAuthData: getPreAuthDataPromise,
  });

  const preAuthData = useReduxState((state) => selectPreAuthData(state), []);

  const failedRequestTokens = useMemo(() => {
    if (preAuthData) {
      return preAuthData.reduce((failed, data, provider) => {
        // this is an immutable js Map reduce
        if (data.has("error") /* || !data.get("canSubmit") */) {
          return [...failed, provider];
        }

        return failed;
      }, []);
    }

    return [];
  }, [preAuthData]);

  const requestTokensLoaded = useMemo(
    () =>
      !!preAuthData &&
      preAuthData.some(
        (providerData) =>
          providerData &&
          (providerData.get("canSubmit") || providerData.has("error"))
      ),
    // above is an Immutable js Map .some
    [preAuthData]
  );

  useEffect(() => {
    if (!preAuthData) {
      getPreAuthData({
        additionalParams: {
          oauthAction: "login",
          ...additionalParams,
        },
      });
    }
  }, [getPreAuthData, preAuthData, additionalParams]);

  return {
    preAuthData,
    requestTokensLoaded,
    failedRequestTokens,
  };
};

export default usePreAuthData;
