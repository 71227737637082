import { faBug } from "@fortawesome/free-solid-svg-icons/faBug";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faLink } from "@fortawesome/pro-solid-svg-icons/faLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import { socialImportStyles } from "components/Auth/AuthForms/Layout/loginStyles";
import StandardButton from "components/Buttons/StandardButton";
import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import SocialConnectErrorMessage from "./SocialConnectErrorMessage";

import authActions from "actions/auth";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  tooltip: {
    display: "block",
    width: "100%",
    height: "100%",
  },
  socialImportButtonWithLabel: {
    ...socialImportStyles.socialImportButtonWithLabel,
    width: "100%",
    fontSize: ".9rem",
  },
  ...socialImportStyles,
};

function SocialConnectActionButton(props) {
  const {
    type,
    returnUrl,
    onConnectedClick,
    isSelected,
    stacked,
    icon,
    buttonStyles: passedButtonStyles,
    submitting,
    disabled,
    onUnselect,
    error,
    additionalParams,
    renderTooltip,
    title,
    actionContext,
    label,
    noActiveState,
    disableErrors,
  } = props;

  const user = useLoggedInUser();
  const { startSocialConnectFlow } = useActionCreators({
    startSocialConnectFlow: authActions.startSocialConnectFlow,
  });

  const isConnected =
    user && user.get("connected_social_networks", []).includes(type);

  const handleClick = useCallback(() => {
    sendGAEvent({
      action: "socialConnectButtonClicked",
      connected: isConnected,
      context: actionContext,
    });
    if (isConnected) {
      onConnectedClick();
    } else {
      // start social connect flow for this
      startSocialConnectFlow({
        provider: type,
        returnUrl: returnUrl || window.location.href,
        additionalParams,
      });
    }
  }, [
    isConnected,
    actionContext,
    onConnectedClick,
    startSocialConnectFlow,
    type,
    returnUrl,
    additionalParams,
  ]);

  const { styles } = useStyles(baseStyles, props);

  const buttonStyles = useMemo(
    () => ({
      hover: {
        width: "100%",
        height: "100%",
      },
      ...passedButtonStyles,
    }),
    [passedButtonStyles]
  );

  const renderButton = () => (
    <StandardButton
      dataId={`social-connect-action-${type}`}
      dataValue={isSelected ? "selected" : "unselected"}
      customStyles={buttonStyles}
      label={icon ? <FontAwesomeIcon icon={icon} /> : label || type}
      active={noActiveState || isSelected}
      onClick={handleClick}
      submitting={submitting}
      disabled={disabled}
      title={title}
    />
  );

  return (
    <div
      data-id={`social-connect-action-${type}-container`}
      className={css(styles.socialConnectActionButton)}
    >
      <SocialConnectErrorMessage
        className={css(
          styles.socialImportButton,
          label && styles.socialImportButtonWithLabel,
          stacked && styles.socialImportButtonStacked
        )}
        onClick={handleClick}
        type={type}
        disableErrors={disableErrors}
      >
        {isConnected && (
          <div
            dataId={`social-connect-action-${type}-linked`}
            className={css(styles.socialImportClose)}
          >
            <FontAwesomeIcon icon={faLink} />
          </div>
        )}
        {!noActiveState && isSelected && onUnselect && (
          <div
            data-id={`social-connect-action-close-${type}`}
            className={css(styles.socialImportClose)}
            onClick={onUnselect}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {error && (
          <div
            className={css(styles.socialImportError)}
            onClick={onUnselect}
            title={
              typeof error === "string"
                ? error
                : "There was an problem signing in"
            }
          >
            <FontAwesomeIcon icon={faBug} />
          </div>
        )}

        {renderTooltip ? (
          <BasicTooltip renderContent={renderTooltip}>
            {(tooltipProps) => (
              <span {...tooltipProps} className={css(styles.tooltip)}>
                {renderButton()}
              </span>
            )}
          </BasicTooltip>
        ) : (
          renderButton()
        )}
      </SocialConnectErrorMessage>
    </div>
  );
}

SocialConnectActionButton.propTypes = {
  type: PropTypes.string.isRequired,
  returnUrl: PropTypes.string,
  onConnectedClick: PropTypes.func,
  isSelected: PropTypes.bool,
  stacked: PropTypes.bool,
  icon: PropTypes.object,
  buttonStyles: PropTypes.object,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  onUnselect: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  additionalParams: PropTypes.object,
  renderTooltip: PropTypes.func,
  title: PropTypes.node,
  actionContext: PropTypes.string,
  label: PropTypes.node,
  noActiveState: PropTypes.bool,
  disableErrors: PropTypes.bool,
};

SocialConnectActionButton.defaultProps = {
  icon: null,
  onConnectedClick: null,
  returnUrl: null,
  isSelected: false,
  stacked: false,
  buttonStyles: {},
  submitting: false,
  disabled: false,
  onUnselect: null,
  error: null,
  additionalParams: null,
  renderTooltip: null,
  title: null,
  actionContext: "not_defined",
  label: null,
  noActiveState: false,
  disableErrors: false,
};

export default memo(SocialConnectActionButton);
